#hi-eu-opt-in .opt-in-splash {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 58px;
    height: 58px;
}

#hi-eu-opt-in .opt-in-splash.unconfirmed {
    background: url(/images/cookieimg.png) 0 -10px no-repeat;
    cursor: pointer;
}

#hi-eu-opt-in .opt-in-splash.confirmed {
    background: url(/images/cookieimg.png) 0 -68px no-repeat;
    display: none;
}

#hi-cookie-box {
    background: url(/images/cookieimg.png) -64px 0 no-repeat;
    width: 190px;
    height: 190px;
    font-size: 11px;
    text-align: center!important;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    position: fixed;
    bottom: 24px;
    left: 24px;
}

.container-full {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 320px;
    position: relative;
}

.video-canvas {
    color: white;
    text-align: center;
    margin: 0px auto;
    clear: both;
    height: 850px;
    background-color: #fff;
    background-image: url('/images/bg/silver-abstract.jpg');
    clear: both;
}

.c_green {
    color: #8CC152;
}

.c_yellow {
    color: #FFC107;
}

.full p {
    font-size: 150%;
    line-height: 150%;
}

.checklevel {
    width: 18px;
    height: 18px;
}

#hi-cookie-box p {
    text-align: center!important;
}

#hi-cookie-close {
    display: block;
    float: right;
    background: #222;
    padding: 3px 7px;
    border-radius: 3px;
    cursor: pointer;
}

#hi-cookie-close:hover {
    background: #666;
    color: #fff!important;
}

#hi-cookie-box p {
    padding: 0;
    margin: 0;
}

#hi-cookie-box .hi-cookie-head {
    font-size: 15px;
    padding: 20px 0 3px;
}

#hi-cookie-box .hi-cookie-intro {
    color: #ccc;
    font-size: 10px;
    padding-bottom: 10px;
    line-height: 1em;
    white-space: pre;
}

#hi-cookie-box a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
}

#hi-cookie-box a:hover {
    color: #ccc;
}

#hi-cookie-box .hi-cookie-power {
    font-size: 9px;
}

#hi-cookie-box div.hi-cookie-btn {
    cursor: pointer;
    margin: 5px 10px 8px 55px;
    float: left;
    display: block;
    background: url(/images/cookieimg.png) 0 -126px no-repeat;
    width: 32px;
    height: 32px;
    text-indent: -100000px;
}

#hi-cookie-box div.hi-cookie-btn.agree {
    margin-left: 55px;
    background-position: 0 -126px;
}

#hi-cookie-box div.hi-cookie-btn.disagree {
    margin-left: 0;
    background-position: -32px -126px;
}

#hi-cookie-box div.hi-cookie-btn.agree:hover {
    background-position: 0 -158px;
}

#hi-cookie-box div.hi-cookie-btn.disagree:hover {
    background-position: -32px -158px;
}

.navbar-nav > li > a.navbar-brand-logo {
    padding: 0;
}

footer {
    width: auto;
    height: auto;
}

.footer-wrap {
    margin: 10px auto;
    max-width: 700px;
}

.img-responsive-bee {
    width: 100%
}

#page-content {
    background-color: transparent;
}

.loginmodal-container {
    padding: 20px;
    max-width: 1000px;
    width: 80% !important;
    background-color: #F2CC11;
    margin: 0 auto;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    border: 1px solid #03A9F4;
}

.loginmodal-container h1 {
    text-align: center;
    font-size: 1.8em;
    color: #967D00;
}

.loginmodal-container input[type=submit] {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    position: relative;
}

.loginmodal-container input[type=text],
.loginmodal-container input[type=email],
.loginmodal-container input[type=password] {
    height: 44px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    /* border-radius: 2px; */
    padding: 0 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.loginmodal-container input[type=text]:hover,
input[type=email]:hover,
input[type=password]:hover {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.loginmodal {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    padding: 0 8px;
    /* border-radius: 3px; */
    /* -webkit-user-select: none;
user-select: none; */
}

.loginmodal-submit {
    /* border: 1px solid #3079ed; */
    border: 0px;
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    background-color: #16A085;
    padding: 17px 0px;
    font-size: 14px;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}

.loginmodal-submit:hover {
    /* border: 1px solid #2f5bb7; */
    border: 0px;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    background-color: #006A55;
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}

.loginmodal-container a {
    text-decoration: none;
    color: #CE0E5F;
    font-weight: 400;
    text-align: center;
    display: inline-block;
    opacity: 0.6;
    transition: opacity ease 0.5s;
}

.login-help {
    font-size: 14px;
}

#login .modal-header,
#call .modal-header {
    border: 0 none;
    color: #16A085;
}

.mt20 {
    margin-top: 20px;
}

.footer-wrap a {
    color: #607D8B;
}


/**
* retailer
*/

.intro-inner {
    width: 100%;
    clear: both;
    height: 350px;
    overflow: hidden;
}

.about-intro {
    width: 100%;
    height: 100%;
    position: relative;
}

.width100,
.w100 {
    width: 100%;
}

.hw100 {
    height: 100%;
    width: 100%;
}

.dtable {
    display: table;
}

.dtable-cell {
    display: table-cell;
    vertical-align: middle;
}

hr.small {
    background-color: #000;
    height: 2px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 44px;
}

.text-hr {
    margin-bottom: 60px !important;
    margin-top: 0 !important;
}

.intro h1,
.intro-title {
    color: #fff;
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    transition: font 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}

.inner-box {
    background: none repeat scroll 0 0 #FFFFFF;
    border-radius: 3px 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(180, 180, 180, 0.5);
    margin-bottom: 30px;
    padding: 20px 15px;
}

.page-content .inner-box {
    overflow: auto;
    padding: 15px;
}

.ads-details-wrapper.inner-box {
    padding-bottom: 0;
}

.list-title a,
.list-title {
    color: #222;
    font-size: 18px;
}

.list-title {
    border-bottom: 1px solid #ddd;
    display: block;
    margin-bottom: 15px;
    padding: 10px 0;
    position: relative;
    text-transform: uppercase;
}

.mh20 {
    margin: 20px auto;
}

.ads-details-wrapper h2 {
    font-size: 24px;
    line-height: 28px;
    color: #222;
}

.page-sidebar-right {
    padding-left: 10px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.sidebar-panel .panel-heading {
    background: #f8f8f8;
    position: relative;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 15px;
}

.seller-info {
    padding: 5px;
}

.user-ads-action {
    margin-top: 15px;
}

.user-info h3 {
    padding-bottom: 5px;
}

.no-margin {
    margin: 0 !important;
}

.list-check > li {
    margin-bottom: 3px;
    padding-left: 18px;
    position: relative;
}

ul.list-check {
    list-style: none;
    margin: 0;
    padding: 0;
}

.btn-claim {
    background-color: #2357A5;
    color: #FFFFFF;
}

.btn-claim:hover,
.btn-claim:focus,
.btn-claim:active,
.btn-claim.active,
.open .dropdown-toggle.btn-claim {
    background-color: #406EB1;
    border-color: #406EB1;
    color: #FFFFFF;
}

.btn-cashback {
    background-color: #16A085;
    border-color: #16A085;
    color: #fff;
}

.btn-cashback:hover,
.btn-cashback:focus,
.btn-cashback:active,
.btn-cashback.active,
.open .dropdown-toggle.btn-cashback {
    background-color: #149078;
    border-color: #149078;
    color: #FFFFFF;
}

.panel-details {
    border: 1px solid #ddd;
    background: #f8f8f8;
    position: relative;
}

.content-footer {
    background: #f8f8f8;
    position: relative;
    padding: 8px;
    border-top: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
}

.ads-details-wrapper .content-footer,
.content-footer.expand {
    margin-left: -15px;
    margin-right: -15px;
}


/**
* insurance_comparison
*/

.cloud_bg,
.clouds {
    width: 100%;
    margin: 0;
    background: url(/images/bg/silver-bg.png) #FFFBEA;
    background-size: cover;
    min-height: 800px;
    height: 100%;
}


/**
* energy_comparison
*/

.energy_comparison {
    width: 100%;
    margin: 0;
    background: url(/images/bg/energy_comparison.jpg) #FFFBEA;
    background-size: cover;
}

.energy_comparison_content {
    min-height: 800px;
    color: #fff;
    margin-top: 25px;
    padding: 25px;
}

.c222 {
    color: #222;
}

.intro {
    width: 100%;
    background: url(/images/bg/energy_comparison.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
    transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
    -webkit-transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}

button.btn-search {
    border-radius: 0 !important;
    font-size: 18px;
    height: 48px;
    letter-spacing: -0.5px;
    text-shadow: 0 2px 2px #20a759;
    -webkit-text-shadow: 0 2px 2px #20a759;
    text-transform: uppercase;
}

.search-row .btn-search {
    border-radius: 0 3px 3px 0 !important;
}

.search-row button.btn-search {
    border-radius: 0;
    border: 0;
    height: 48px;
    margin-bottom: 0;
}

.search-row .search-col {
    padding: 0;
    position: relative;
}

.search-row .search-col {
    padding: 0;
    position: relative;
}

.relative {
    position: relative;
}

.cat-list {
    display: block;
    margin-bottom: 30px;
    line-height: 24px;
}

.cat-list.arrow {
    margin-bottom: 0;
}

.cat-list.arrow li {
    background: url("/ui/dashboard/images/arrow-right.png") no-repeat scroll left center rgba(0, 0, 0, 0);
    border-bottom: 1px dotted #ddd;
    padding: 5px 14px;
}

.cat-list.arrow li a {
    color: #607D8B;
}

h2.title-2 {
    font-size: 120%;
}

ul.cat-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.c-green {
    color: #16A085;
}

.blink {
    width: 100%;
    margin: 0 0%;
    background: url(/images/bg_blue2.jpg) #FFFBEA;
    background-size: cover;
}

.plan>.plan-title>h3,
.plan>.plan-price>h3,
.plan>.plan-price>h4,
.plan>.plan-price>h3 {
    color: #333;
}

h1.content-head {
    text-align: center;
    color: #009688;
}


/* category */

.category-list {
    background: none repeat scroll 0 0 #ffffff;
    border-radius: 3px 3px;
    display: block;
    box-shadow: 0 1px 1px rgba(180, 180, 180, 0.5);
}

.tab-box {
    background: #f8f8f8;
    position: relative;
}

.listing-filter {
    border-bottom: solid 1px #ddd;
    padding: 15px 0;
}

.adds-wrapper {
    background: #fff;
    clear: both;
    display: block;
    height: auto;
    overflow: auto;
    width: 100%;
}

.item-list {
    border-bottom: 1px solid #ddd;
    clear: both;
    padding: 15px;
    height: auto;
    width: 100%;
    display: block;
    position: relative;
    min-height: 150px;
}

.no-padding {
    padding: 0 !important;
}

.add-image {
    position: relative;
}

.add-image a {
    display: block;
}

.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border .2s ease-in-out;
}

.no-margin {
    margin: 0 !important;
}

.add-image a img {
    width: 100%;
}

.add-title {
    padding-bottom: 7px;
}

.add-details a {
    font-weight: bold;
}

.info-row {
    display: block;
    clear: both;
    font-size: 12px;
    color: #9a9a9a;
}

.price-box {
    padding: 0;
}

.bee-pointz {
    background: none repeat scroll 0 0 #F7FF28;
    border: 1px solid #1ACA87;
    border-radius: 10%;
    display: inline-block;
    height: 25px;
    line-height: 18px;
    padding: 3px;
    text-align: center;
    width: 42px;
    font-size: 18px;
}

@media (min-width: 786px) and (max-device-width: 1980px) {
    .img-responsive {
        width: auto;
    }
    body.page-header-fixed .header-right {
        left: 0;
    }
    .header-right .navbar-toolbar:before {
        border-bottom: 0 solid #DDD;
    }
    .nav > li > a.bee-logo {
        padding: 0;
    }
    .navbar-toolbar .navbar-left .navbar-minimize {
        width: 75px;
        text-align: center;
        border-right: none;
    }
    .navbar-header {
        display: none;
    }
    .img-responsive-bee {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .cloud_bg,
    .clouds {
        min-height: 800px;
        height: 100%;
    }
}

@media (max-width: 768px) {
    #header .navbar-minimize-mobile {
        position: absolute;
        vertical-align: top;
        text-align: center;
        height: auto;
        line-height: 43px;
        cursor: pointer;
        padding-top: 8px;
        width: 65px;
        z-index: 1;
    }
    .navbar-nav > li > a.bee-logo {
        display: none;
    }
    /*.navbar-nav > li.navbar-profile*/
    .navbar-nav > li.navbar-search {
        width: 80%;
    }
}

@media (max-width: 480px) {
    #header .navbar-search {
        display: block !important;
    }
}

@media (max-width: 360px) {
    #header .navbar-left .navbar-search {
        display: block !important;
    }
}

@media (max-width: 600px) {
    #header .navbar-toolbar .navbar-form input {
        min-width: 50%;
    }
}

.sidebar-light .sidebar-menu > li > a > span,
.sidebar-light .sidebar-menu > li > a > .icon i {
    color: #008132;
}

.sidebar-light .sidebar-menu > li.active > a > .icon i {
    color: #008132 !important;
}

.sidebar-light .sidebar-menu > li > ul > li.active a,
.sidebar-light .sidebar-menu > li > ul > li.active a span {
    background-color: #FFFFFF !important;
    color: #000;
}

.navbar-toolbar .navbar-right .navbar-profile > a .meta .avatar {
    width: 30px;
    height: 30px;
    margin-top: 5px;
}

.bintro {
    display: table;
    width: 100%;
    height: auto;
    padding: 100px 0;
    text-align: center;
    color: #fff;
    background: url({{ $settings->banner_1 }}) no-repeat bottom center scroll;
    background-color: #000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    position: relative;
    overflow: hidden;
}

.bintro .bintro-body {
    display: table-cell;
    vertical-align: middle;
}

.bintro .bintro-body .brand-heading {
    font-size: 40px;
}

.bintro .bintro-body .bintro-text {
    font-size: 18px;
}

.bimg {
    display: none;
}

@media(min-width:768px) {
    .bintro {
        min-height: 100% !important;
        height: 100%;
    }
    .bintro {
        height: 100%;
        padding: 0;
    }
    .bintro .bintro-body .brand-heading {
        font-size: 100px;
    }
    .bintro .bintro-body .bintro-text {
        font-size: 26px;
    }
}

@media screen and (max-width:998px),
screen and (max-height: 700px) {
    .bimg {
        display: block;
    }
    .bintro {
        display: none;
    }
}

.full {
    width: 100%;
    margin: 0;
}

#about {
    background: url(/images/bg/networking.jpg) repeat-x;
    background-size: cover;
}

.section-about-bg {
    background-color: #323A45;
    opacity: .8;
}

.b_about {
    padding-top: 10px;
    padding-bottom: 10px;
}

.colFullTabout {
    margin: 50px auto;
    max-width: 940px;
}

.c_fff {
    color: #4C4F46;
    padding-top: 90px;
}

.txt_white_bg {
    background-color: rgba(52, 113, 132, .5);
}

.txt_white_bg a,
.txt_white_bg li,
.txt_white_bg p {
    color: #FDFDFD;
}

#about h2,
#about p,
#bee_pointz h2,
#bee_pointz p,
#contact h2,
#features h2,
#features p,
.thanks_info h3,
.thanks_info h4 {
    color: #fff;
}

#bee_pointz {
    width: 100%;
    background: url(/images/speedybg.jpg) repeat-x;
}

#philosophy {
    width: 100%;
    height: 305px;
    margin: 0;
    background-image: url(/images/delighted_bg.jpg);
    background-repeat: repeat-x;
}

#bee_pointz_content,
#feedback_content,
#how_it_works_content,
#philosophy_content {
    max-width: 1000px;
    height: 305px;
    margin: 0 auto;
}

.bee_pointz_heading {
    margin-left: 20px;
    color: #FFF;
    padding-top: 45px;
    max-width: 400px;
}

.how_it_works_heading {
    margin-left: 20px;
    margin-top: 30px;
    color: #000;
    padding-top: 20px;
    width: 100%;
    float: left;
}

.philosophy_heading {
    margin-left: 20px;
    color: #FFF;
    padding-top: 45px;
    max-width: 530px;
}

.feedback_heading {
    margin-left: 20px;
    margin-top: 30px;
    color: #000;
    padding-top: 20px;
    width: 100%;
    float: left;
}

.bee_pointz_featured_img {
    width: 435px;
    max-height: 264px;
    float: right;
    margin-top: 41px;
}

.how_it_works_featured_img {
    margin-top: 40px;
}

.how_it_works_featured_imgd {
    width: 258px;
    max-height: 258px;
    float: left;
    margin-top: 28px;
}

.philosophy_featured_img {
    width: 420px;
    max-height: 249px;
    float: right;
    margin-top: 56px;
    margin-right: -2px;
}

.feedback_featured_img {
    float: left;
    margin-top: 40px;
}

.b_featured_text {
    max-width: 500px;
    margin-left: 20px;
    margin-top: 20px;
    text-align: justify;
    color: #FFF;
    line-height: 200%;
}

.c_fff {
    color: #4C4F46;
    padding-top: 90px;
}

.navbar-profile {
    margin-right: 5px;
}

.nav-ay {
    max-width: 450px;
    width: 450px;
    margin-top: 10px;
    margin-bottom: -10px;
}

.activity-progress {
    width: 150px;
}

.b_featured_text {
    font-size: 150%;
}

body.page-sidebar-minimize #sidebar-left {
    display: none;
}

body.page-sidebar-minimize #page-content {
    margin-left: 0;
}

body .modal-ticket {
    width: 460px;
}

.video-canvas {
    color: white;
    text-align: center;
    margin: 0px auto;
    clear: both;
    height: 850px;
    background-color: #fff;
    background-image: url('/images/slider/merchantlogos.png');
}

.modal-header-primary {
    border-bottom: 1px solid #eee;
    background-color: #f2cc11;
    color: #fff;
}

h3 {
    margin: 0;
}

.fa-check {
    color: #2aa52f;
    font-size: 1.2rem;
}

.item-list {
    border-bottom: 0 none;
    min-height: auto;
    padding: 0;
    color: #2aa52f;
    font-size: 1.4rem;
    width: 90%;
}

.btn-start {
    display: block;
    padding: 12px 60px;
    background: #7cbb38;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    vertical-align: middle;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border-color: #7cbb38;
}

.btn-start:hover,
.btn-start:focus,
.btn-start:active,
.btn-start.active,
.btn-start[disabled],
.btn-start.disabled {
    cursor: pointer;
    color: #fff;
    background: rgba(76, 175, 80, 0.78);
    text-decoration: none;
    border-color: rgba(76, 175, 80, 0.78);
}

.c_yellow {
    color: #2aa52f;
    font-size: 4.375rem;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

.video-canvas {
    height: auto;
}

.intro-text {
    padding-bottom: 20px;
    margin-top: 20px;
}

.fa-ul li {
    line-height: 150%;
    font-weight: bold;
}

.venobox {
    margin-bottom: 5px;
}

.btn-start icon {
    font-size: 2rem;
}

.product-search {
    max-width: 390px;
    width: 390px;
    margin-top: 10px;
    margin-bottom: -10px;
}

.product-search-select {
    max-width: 100px;
    background-color: #f3f3f3;
    background-image: none;
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding-right: 0 !important;
    font-size: 14px !important;
}

.product-search-input {
    max-width: 295px;
    margin-left: -2px;
    position: absolute;
    top: 0;
    left: 100px;
    font-size: 14px !important;
    z-index: 1
}

#google_translate_element {
    margin-top: 10px;
}

.refer-li {
    width: 100px;
    margin-top: 8px;
}

#refer-btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #8CC152;
    border-color: #8CC152;
    color: white;
}

.activity-progress span,
.activity-progress-mobile span {
    font-size: 13px;
    color: #008132;
    font-weight: 600;
}

.activity-progress-header {
    height: 12px;
    margin-bottom: 0;
}

.progress {
    background-color: #FFB300;
}

.refer-btn-text {
    font-size: 13px;
    color: #008132;
    font-weight: 600;
    padding-top: 10px;
    color: #fff;
}

.header-account {
    line-height: 15px;
    color: #008132;
    padding-top: 8px;
}

.header-earnings {
    line-height: 15px;
    margin-top: 0px;
    color: #008132;
}

.header-earnings-amount {
    margin-top: 2px;
    display: block;
    line-height: 15px;
    padding-left: 5px;
    color: #008132;
}

.fs30 {
    font-size: 30px !important;
}

.lh30 {
    line-height: 30px !important;
}

.navbar-toolbar .navbar-right .navbar-profile > a .meta {
    line-height: 20px;
}

@media(max-width:460px) {
    body .modal-ticket {
        width: 95%;
    }
}

@media(min-width:767px) {
    .has-cta .cta-wrapper a {
        display: table-cell;
    }
}

@media(max-width:767px) {
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
        text-align: left;
        padding-left: 25px;
        border-bottom: 1px solid #eaeaea;
    }
}

@media (max-width: 992px) {
    .feedback_featured_img,
    .how_it_works_featured_img {
        width: 100%;
        background-position: center center;
    }
    #philosophy {
        height: auto;
        background-image: none;
        background-color: #D74D66;
    }
    #bee_pointz {
        height: auto;
        background-image: none;
        background-color: #5DB38E;
    }
}

@media (max-width: 768px) {
    #header .navbar-nav {
        max-width: 50%;
        width: 50%;
    }
    #header .nav > li.navbar-profile {
        float: right;
    }
}

@media (max-width: 768px) {
    #sidebar-left {
        top: 100px;
        left: -220px;
        z-index: 9999;
        position: absolute;
    }
    .activity-progress-mobile {
        width: 100%;
        position: relative;
        background: #fff;
        padding: 10px;
        z-index: -100;
    }
    #page-content {
        margin-top: 50px;
    }
    .c_yellow {
        font-size: 200%;
        line-height: 200%;
    }
    body.page-sidebar-fixed #sidebar-left {
        left: -220px;
    }
    #header .navbar-bee-header .navbar-bee-brand {
        width: 100%;
    }
    #header .navbar-bee-header .navbar-bee-brand .logo {
        display: block !important;
        margin: 0px auto;
    }
    #header .navbar-bee-minimize-mobile,
    #header .navbar-bee-header {
        float: left;
        display: block;
        position: relative;
    }
    #header .navbar-bee-minimize-mobile {
        position: absolute;
        vertical-align: top;
        text-align: center;
        height: 50px;
        line-height: 43px;
        cursor: pointer;
        padding-top: 8px;
        width: 65px;
        z-index: 1000;
    }
    #header .navbar-bee-minimize-mobile.left {
        left: 0px;
    }
    .header-right {
        display: none;
    }
    #header {
        background-color: #FFB300;
    }
    #header .navbar-bee-header .navbar-bee-brand {
        width: 100%;
    }
    .header-left,
    #header .navbar-bee-header {
        width: 100%;
    }
    body.page-sidebar-left-show #sidebar-left {
        top: 0px;
        left: 0px;
    }
    body.page-sidebar-left-show #header {
        left: 220px;
    }
    .TopSearchBar {
        display: block;
    }
    .navbar-account-mobile {
        position: absolute;
        top: 10px;
        right: 15px;
        list-style: none;
    }
    .header-right {
        z-index: -1;
    }
    #header .nav > li.navbar-profile {
        display: none;
    }
    .open>.dropdown-menu {
        margin-left: -10px;
    }
    .btn-start {
        font-size: 120%
    }
    .full h2 {
        font-size: 180%;
        line-height: 180%;
    }
}

body.page-header-fixed #sidebar-right .panel .panel-body {
    margin-top: 0;
    padding-top: 20px;
}

.sidebar-profile .media-list > .media .media-body .media-meta {
    color: #ddd !important;
}

#sidebar-right .sidebar-category,
.sidebar-profile .media-list > .media .media-heading {
    color: #fff !important;
}

#sidebar-right {
    right: -300px;
    width: 300px;
}

body.page-sidebar-right-show #page-content {
    margin-right: 300px !important;
}

.sidebar-profile .media-list {
    width: 300px;
}

#sidebar-right .media-list > .media .media-object img,
#sidebar-right .sidebar-profile .media-list.working > .media .media-object {
    width: 35px;
    height: 35px;
}

.sidebar-profile .media-list > .media .media-body {
    width: 250px;
    padding-left: 5px;
}

#beezone-cart .media-list > .media .media-body .delete_cart_item {
    color: #ff2400 !important;
    font-size: 20px !important;
}

#sidebar-right .media-list > .media > .media-body > .media-heading {
    max-width: 190px;
}

#sidebar-right .sidebar-profile .media-list > .media .media-heading {
    font-size: 12px;
}

#sidebar-right .cart_checkout {
    margin-top: 20px;
}

.navbar-setting > a .count {
    position: absolute;
    top: 8px;
    right: 2px;
    padding: 0.4em 0.6em;
}


/** Price comparison */

.TopBannerText .title {
    font-weight: bold;
    color: black;
}

.TopBannerText .text p {
    color: black;
}

.infinite-bgimg {
    margin: 0 auto;
    text-align: center;
}

.TopLogo {
    margin: 20px
}

.TopBannerContainer {
    height: 554px
}

.TopBannerImage {
    max-height: 554px
}

.TopBannerText {
    position: absolute;
    opacity: 0.7;
    border-radius: 10px;
    max-width: 350px;
    padding: 10px;
    margin-left: 250px;
    margin-top: 250px;
    background-color: #faa71c;
    text-align: left;
    color: black
}

.TopBannerText H1 {
    margin: 0px;
}

.TopInnerBanner {
    position: absolute;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    z-index: 9
}

.TopLogoBar {
    position: absolute;
    width: 100%;
    z-index: 10
}

.TopLogoBar .row {
    margin: 0px
}

.TopLinksBar {
    height: 42px;
    background-color: #fff;
}

.TopLinksBarLeft {
    height: 42px;
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-gradient(linear, left top, right top, (0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=1)
}

.TopLinksBarLinks {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 42px;
    z-index: 9;
    text-align: center;
    color: #ddd
}

.TopLinksBarLinks A:link,
.TopLinksBarLinks A:visited {
    padding: 0px 15px;
    color: white;
    text-decoration: none
}

.TopLinksBarLeft {
    height: 42px;
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-gradient(linear, left top, right top, (0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000', GradientType=1)
}

.TopLinksBarLinks {
    position: relative;
    overflow: hidden;
    height: 32px;
    z-index: 9;
    text-align: center;
    color: #ddd;
    text-align: center;
    margin: 12px auto 0;
}

.TopLinksBarLinks A:link,
.TopLinksBarLinks A:visited {
    padding: 0px 15px;
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.AllCatsDDR {
    padding-left: 20px;
    margin: 0px 0px;
    cursor: pointer
}

.AllCatsDropMenu {
    width: 270px;
    max-height: 400px;
    padding-top: 10px;
    text-align: left;
    background-color: #000;
    opacity: 0.95;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    z-index: 999;
    left: 0;
    top: 0;
}

.AllCatsDropMenuDDR {
    display: none;
    z-index: 999
}

.nav-cats {
    height: 250px;
    margin: 0px 15px
}

.nav-cats .right-caret {
    border-left-color: white
}

.nav-cats>li>a:link,
.nav-cats>li>a:visited {
    font-weight: bold;
    color: #000;
}

.nav-cats>li>a:hover {
    color: #000;
    background-color: #fff
}

.nav-cats .right-menu {
    width: 240px;
    left: 230px
}

.dropdown-menu > li > a:hover,
.nav-cats .dropdown-menu > li > a:hover {
    color: #000;
}

.nav-cats button {
    float: right;
    margin-right: -8px;
    padding: 3px 5px;
    margin-top: -3px;
    background-color: #61b4e8;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    display: none
}

.nav-cats button .icon-bar {
    display: block;
    border: 1px solid white;
    width: 20px;
    height: 2px;
    border-radius: 1px;
}

.nav-cats button .icon-bar+.icon-bar {
    margin-top: 4px;
}

.nav-cats a:hover .right-caret {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid black;
    display: inline-block;
    height: 0;
    opacity: 1;
    width: 0
}

.nav-cats .dropdown-menu > li {
    background-color: #000
}

.nav-cats .dropdown-menu > li > a {
    color: #fff
}

@media (max-width:767px) {
    .AllCatsDropMenu {
        height: auto;
        padding-bottom: 15px;
        overflow: auto
    }
    .nav-cats {
        margin: 0px 15px
    }
    .nav-cats .right-menu {
        width: 100%;
        position: static;
        float: none;
        background: none;
        border: none;
        box-shadow: none
    }
    .nav-cats .dropdown-menu a:link,
    .nav-cats .dropdown-menu a:visited {
        min-height: 30px;
        padding-top: 8px
    }
    .nav-cats .dropdown-menu li {
        padding-left: 8px
    }
    .nav-cats a:hover button {
        display: block
    }
    .nav-cats a:hover .right-caret {
        display: none
    }
}

@media (max-width:992px) {
    .right-caret-wrapper {
        background-color: #cdd6db;
        border: 1px solid white
    }
}

@media (max-width:1200px) {
    .nav-cats>li>a:link,
    .nav-cats>li>a:visited {
        font-weight: normal;
        font-size: 11px;
        padding: 5px 10px
    }
}

.nav-cats a:hover .right-caret-wrapper {
    background-color: transparent;
    border: none
}

.TopSearchBar {
    position: absolute;
    width: 100%;
    z-index: 9
}

.TopSearchBar .row {
    margin: 0px
}

@media (max-width: 1200px) {
    .TopBannerContainer {
        height: 400px
    }
    .TopBannerImage {
        max-height: 400px
    }
    .TopLogo {
        margin: 15px 0px
    }
    .TopLinksBarLinks A:link,
    .TopLinksBarLinks A:visited {
        padding: 0px 5px;
    }
    .TopBannerText {
        max-width: 270px;
        margin-left: 250px;
        margin-top: 150px;
        font-size: 12px
    }
    .TopBannerText H1 {
        font-size: 16px
    }
}

@media (max-width: 992px) {
    .TopBannerContainer {
        height: 300px
    }
    .TopBannerImage {
        max-height: 300px
    }
    .TopBannerText {
        display: none
    }
}

@media (max-width: 768px) {
    .TopBannerContainer {
        height: 150px;
        background: url(../images/banner-1.jpg) no-repeat center center;
        background-size: 100% auto
    }
    .TopInnerBanner {
        height: 150px
    }
    .TopBannerText {
        display: none
    }
}

.SearchTextCont {
    position: absolute;
    width: 100%;
    height: 65px;
    background-color: #000;
    opacity: 0.65
}

.SearchTextBg {
    height: 47px;
    margin: 11px auto
}

.SearchTextBg .form-control {
    border-right: none
}

.SearchTextBg .input-group-addon {
    background-color: #fff
}

.SearchTextBg .glyphicon {
    font-size: 16px
}

.right-caret-wrapper {
    padding: 10px;
    border-radius: 5px;
    float: right;
    margin-top: -5px
}

.right-caret {
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 6px solid red;
    display: inline-block;
    height: 0;
    opacity: 1;
    width: 0
}

.right-menu {
    position: absolute;
    left: 320px;
    top: 0px;
}

.nav-cats>li>a:link {
    font-weight: bold;
    color: white;
}

.nav-cats>li>a:active,
.nav-cats>li>a:hover,
.nav-cats>li>a:visited {
    color: #000;
}

.TopSearchBar {
    display: block;
    width: 100%;
    z-index: 9;
}

.TopSearchBar .row {
    margin: 0px
}

.SearchTextCont {
    position: absolute;
    width: 100%;
    height: 65px;
    background-color: #000;
    opacity: 0.65
}

.SearchTextBg {
    height: 47px;
    margin: 11px auto
}

.SearchTextBg .form-control {
    border-right: none
}

.SearchTextBg .input-group-addon {
    background-color: #fff
}

.SearchTextBg .glyphicon {
    font-size: 16px
}

.SearchTextBg button {
    padding: 0
}

;
.single-product .white-block.product-box .white-block-content h4,
.white-block.product-box .white-block-content h4 a {
    font-size: 15px;
}

.single-product .white-block.product-box .white-block-content h4 a,
.white-block.product-box .white-block-content h4 a {
    font-size: 15px;
}

.TopSearchBar {
    display: block;
    width: 100%;
    z-index: 9;
    margin-top: -70px;
}

.w960 {
    width: 960px;
    margin: 0 auto;
}

.w1200 {
    max-width: 1200px;
    margin: 0 auto;
}

.TopLinksBar .nav-cats>li>a {
    color: #000
}

A.arrow-left:link,
A.arrow-left:visited {
    font-size: 32px;
    position: absolute;
    left: 2%;
    top: 45%
}

A.arrow-right:link,
A.arrow-right:visited {
    font-size: 32px;
    position: absolute;
    right: 2%;
    top: 45%
}

.AllCatsDropMenu .nav-cats>li>a,
.AllCatsDropMenu .nav-cats>li>a:active,
.AllCatsDropMenu .nav-cats>li>a:hover,
.AllCatsDropMenu .nav-cats>li>a:visited {
    color: #fff;
    background-color: #000;
}

.OverflowHidden {
    overflow: hidden
}

@media(min-width:767px) {
    #page-content {
        padding-top: 40px;
    }
}


/* The Overlay (background) */

.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    /* 100% width */
    position: absolute;
    /* Stay in place */
    z-index: 20;
    /* Sit on top */
    left: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0);
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.5);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    /*    transition: 0.5s;
*/
}


/* Position the content inside the overlay */

.overlay-content {
    max-width: 900px;
    width: 100%;
    top: 50px;
    margin: auto;
    position: relative;
    background-color: #FBFBFB;
}


/* Position the close button (top right corner) */

.overlay .closebtn {
    position: absolute;
    top: 120px;
    right: 45px;
    font-size: 60px;
    z-index: 100;
}

.products-list img {
    width: 40px;
}

.products-list .media {
    border-bottom: 1px solid #DDD;
    padding: 0;
    margin: 0px;
}

.result-name {
    font-size: 12px;
    background: #fff;
    border: #e1e4e6 1px solid;
    border-radius: 100px;
    padding: 5px 15px;
    margin: 0 5px 10px 0;
}

.results-panel {
    margin-bottom: 0;
}

.results-panel .panel-body {
    position: relative;
    background-color: #FBFBFB;
    padding: 10px 0 10px 10px;
}

.results-panel .panel-heading .panel-title {
    padding: 0;
    font-size: 14px;
}

.product-search-input {
    top: 9px;
}

.media-heading {
    padding-top: 10px;
}

.blog-list a {
    font-size: 16px;
}

.big-search {
    display: block;
    float: left;
    width: 30%;
    margin: 0 auto;
}

.big-search-form {
    margin: 0 auto;
    width: 100%;
}

.navbar-left {
    min-width: 33%;
}

.has-feedback {
    top: 12px;
}

.text-black {
    color: #000;
}

.f-rate {
    font-weight: bold !important;
    padding: 0;
    font-size: 10px !important;
    color: #1c4e61;
}

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }
    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}

@media (max-width: 767px) {
    .big-search {
        display: none;
    }
    .product-search-input {
        top: 0;
    }
}

.search-product-name a {
    color: #5f823a;
    font-weight: bold;
}

.search-categories-name {
    color: #d8a234;
    font-weight: bold;
}

.search-brands-name {
    color: #0596bd;
    font-weight: bold;
}

.footer-list {
    margin-bottom: 10px;
}

.footer-list a {
    color: #2196F3;
}

.footer-list h5 {
    font-weight: bold;
}

.full-rounded {
    -moz-border-radius: 16px !important;
    -webkit-border-radius: 16px;
    border-radius: 16px !important;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 1px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

.mbn40 {
    margin-bottom: -40px;
}


@media (max-width: 768px){
body.page-sidebar-left-show #sidebar-left {
    top: 52px !important;
    height: 100%;
    overflow: scroll;
}
}

li.navbar-notification > a > i.fa {
    position: relative;
    font-size: 22px;
    color: #777;
}

li.navbar-notification > a .count {
    position: absolute;
    top: 8px;
    right: 2px;
    padding: 0.4em 0.6em;
    animation: opacity-badge 0.6s infinite;
    -webkit-animation: opacity-badge 0.6s infinite;
}
.mt-15{
    margin-top: 15px !important;
}

.m0{
    margin: 0;
}

.pad-tb10 {
    padding: 10px 0;
}

.margin-left-m12 {
    margin-left: -12px;
}
.margin-left-m16 {
    margin-left: -16px;
}

li.navbar-auth {
    margin-top: 7px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

[v-cloak] { 
    display:none; 
}
@media (max-width: 768px) {
    .modal-backdrop {
        z-index: -1;
    }
}